import React, { useState } from 'react';

const PhoneNumberButton = ({ phoneNumber }) => {
  const [copied, setCopied] = useState(false);

  const handleClick = () => {
    if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)) {
      window.location.href = `tel:${phoneNumber}`;
    } else {
      const el = document.createElement('textarea');
      el.value = phoneNumber;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      setCopied(true);
      alert("Number saved to clip board")
    }
  };

  return (
    <button onClick={handleClick} id="numberbutton">
        {phoneNumber}
    </button>
  );
};

export default PhoneNumberButton;
