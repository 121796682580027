import React, { useState, useEffect } from 'react';



const ImageSlider = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex(currentIndex => (currentIndex + 1) % images.length);
    }, 8000);
    return () => clearInterval(intervalId);
  }, [images]);

  return (
    <div className="image-slider-container">
    <div className="image-slider" style={{ backgroundImage: `url(${images[currentImageIndex]})` }}></div>
    <p className="image-slider-text">Full service painting for the San Antonio area</p>
  </div>  );
};

export default ImageSlider;
