import React from 'react';
import ImageGrid from './imageGrid';
const Section1 = () => {
  return (
    <div id="section80kdot">
      <div className="header1">
  
        <ImageGrid images={
          [
            'https://cdn-icons-png.flaticon.com/512/5974/5974270.png',
            'https://cdn-icons-png.flaticon.com/512/2236/2236632.png',
            'https://cdn-icons-png.flaticon.com/512/3782/3782629.png',
            'https://cdn-icons-png.flaticon.com/512/5832/5832525.png',

            'https://cdn-icons-png.flaticon.com/512/2946/2946919.png',
            'https://cdn-icons-png.flaticon.com/512/465/465139.png',
            
          ]}
          blurbs={
            [
              "Moving Obstacles",
              "Washing",
              "Repairs",
              "Masking",
              "Brushing",
              "Spraying",
             
             
             
             
            ]
          }
          ></ImageGrid>
      </div>
      <div className="container2">
    <div className="item2">
    <div className="section1-header">Standard Service</div>
            <ul className="section1-list">
            <li>Moving furniture</li>
            <li>Masking</li>
            <li>Filling holes</li>
            <li>Priming</li>
            <li>Spraying/Rolling</li>
            <li>Brushing the details</li>
            <li>Clean up</li>
            </ul>
    </div>
    <div className="item2">
    <div className="section1-header" id="mrspecial">Why Us</div>
            <ul className="section1-list">
            <li>Punctual start and completion dates</li>
            <li>Always paint to cover</li>
            <li>Friendly painters</li>
            <li>Quality Paints</li>
            <li>An attention to detail</li>
            <li>20+ years of experience</li>
            </ul>
    </div>
    <div className="item2">
    <div className="section1-header">Extra services</div>
            <ul className="section1-list">
            <li>Pressure washing</li>
            <li>Popcorn ceiling scraping</li>
            <li>Texturing</li>
            <li>Non-loadbearing carpentry</li>
            <li>Drywall repairs</li>
            <li></li>
            <li></li>
            </ul>
    </div>
  </div>
      {/* <div  id="section80babies">
        <div className="section1">
            <div className="section1-header">Standard Service</div>
            <ul className="section1-list">
            <li>Moving furniture</li>
            <li>Masking</li>
            <li>Filling holes</li>
            <li>Priming</li>
            <li>Spraying/Rolling</li>
            <li>Brushing the details</li>
            <li>Clean up</li>
            </ul>
        </div>
        <div className="section1" id="thekid">
            <div className="section1-header" id="mrspecial">Why Us</div>
            <ul className="section1-list">
            <li>Punctual start and completion dates</li>
            <li>Always paint to cover</li>
            <li>Friendly painters</li>
            <li>Quality Paints</li>
            <li>An attention to detail</li>
            <li>20+ years of experience</li>
            </ul>
        </div>
        <div className="section1">
            <div className="section1-header">Extra services</div>
            <ul className="section1-list">
            <li>Pressure washing</li>
            <li>Popcorn ceiling scraping</li>
            <li>Texturing</li>
            <li>Non-loadbearing carpentry</li>
            <li>Drywall repairs</li>
            <li></li>
            <li></li>
            </ul>
        </div>
      </div> */}
    
    </div>
  );
};

export default Section1;
