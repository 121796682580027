import React from "react";
import img1 from "../images/about.jpg"
const AboutSection = ({text}) => {
  return (
    <div className="about-section-container c2" id="aboutSection">
      <div className="about-section-image-container">
        <img
          src={img1}
          alt="PaintopiaTX"
          className="about-section-image"
        />
      </div>
      <div className="about-section-text-container">
        <h2 className="about-section-header">About Paintopia</h2>
        <p className="about-section-paragraph">
          {text[0]}
        </p>
        <p className="about-section-paragraph">
        {text[1]}        
        </p>
        <p className="about-section-paragraph">
        {text[2]}        
        </p>
      </div>
    </div>
  );
};

export default AboutSection;
