import { useEffect } from "react";
import React from 'react';

const ScrollButton = ({ targetId, label, offset }) => {
var targetRef;
    useEffect(() => {
        // id name is "my-btn"
targetRef = document.getElementById(targetId);


  console.log(targetRef)
}, []);

  const handleClick = () => {
    console.log(targetRef)
    window.scrollTo({
      top: targetRef.offsetTop -offset,
      behavior: 'smooth'
    });
  };

  return (
    <button onClick={handleClick} className="headerButton hoverable">
      {label}
    </button>
  );
};

export default ScrollButton;
