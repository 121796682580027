import React from "react";

const PaintingServices = () => {
  return (
    <div className="servicesContainer ">
      <section id="residentialPainting" >
        <h2 className="backedheader c4">Residential Painting</h2>
        <div className="imageContainer">
          <img src="https://certapro.com/pittsburgh-south/wp-content/uploads/sites/1323/2019/08/certapro-painters-interior-painting.jpg"/>
        </div>
        <div className="textContainer">
        <p>Paintopia's residential painting service is a top-notch solution for homeowners in the San Antonio area. 
          Whether you're looking to refresh a single room or paint your entire home, 
          Paintopia is committed to delivering a beautiful and seamless transformation that exceeds your expectations.</p>
        </div>
      </section>
   
      <section id="cabinetPainting" >
        <h2 className="backedheader c5">Cabinet Painting</h2>
        <div className="imageContainer">
        <img src="https://hgtvhome.sndimg.com/content/dam/images/hgtv/fullset/2013/9/27/0/spray-painting-kitchen-cabinets_4x3.jpg.rend.hgtvcom.616.462.suffix/1400954165059.jpeg"/>
        </div>
        <div className="textContainer">
          <p>Paintopia's cabinet painting service is the perfect solution for homeowners looking to upgrade their kitchen or bathroom cabinets without the cost 
            and hassle of a full renovation.
            Whether you're looking to update your cabinets with a fresh coat of paint or completely transform their look with a new color or finish, 
            Paintopia can deliver the results you want.</p>
        </div>
      </section>
      <section id="commercialPainting" >
        <h2 className="backedheader c6">Commercial Painting</h2>
        <div className="imageContainer">
        <img src="https://servicesplus.net/wp-content/uploads/2019/03/shutterstock_50300647_result.jpg"/>
        </div>
        <div className="textContainer">
        <p>Paintopia's commercial painting service is the go-to choice for businesses in the San Antonio area looking for high-quality and professional painting services. 
          With a commitment to meeting deadlines, adhering to budgets, and delivering exceptional results, Paintopia is the ideal partner for your next commercial painting project.</p>
        </div>
      </section>
    </div>
  );
};

export default PaintingServices;
