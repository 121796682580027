import React from 'react';

const ImageGrid = ({ images, blurbs }) => {
  return (
    <div    className="image-grid-container">
        <div className="image-grid ">
        {images.map((image, index) => (
            <div key={index} className="image-grid-item ">
            <img src={image} alt="" />
            </div>
        ))}
        </div>
    </div>
 
  );
};

export default ImageGrid;