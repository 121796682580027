import React, { useState } from 'react';
import PhoneNumberButton from './phonenumberbutton';

const ContactButton = ( {label}) => {
  const [showForm, setShowForm] = useState(false);

  const handleClick = () => {
    setShowForm(true);
  };

  const handleClose = () => {
    setShowForm(false);
  };

  return (
    <>
      <button className='headerButton hoverable'
        onClick={handleClick}
      >
        {label}
      </button>
      {showForm && (
        <div className='contactForm'
          style={{
         
          }}
        >
            <button className='promptCloseButton'
  onClick={handleClose}
>
  X
</button> 

         
          <h3 style={{ textAlign: '' }}>Email david@paintopiatx.com or contact us here and we will get back to you as soon as possible to schedule a free estimate</h3>
          <form action="https://formspree.io/f/mdovazvb" method="POST">
            <div className='contactInputContainer' >
          
              <input className='contactInput' type="text" id="name" name="name" placeholder='Your Name' required />
            </div>
            <div className='contactInputContainer' >
              
              <input className='contactInput' type="email" id="email" name="email" placeholder='Your Email' required />
            </div>
            <div className='contactInputContainer' >
              
              <textarea className='contactTextArea' id="message" name="message" placeholder='Your Message' required />
            </div>
            
            <button type="submit" style={{ backgroundColor: '#333', color: '#fff', padding: '1rem 2rem' }}>
              Submit
            </button>
          </form>
        
      
          
        
        </div>
      )}
    </>
  );
};

export default ContactButton;
