
import React, {Component} from "react";
import {  Route, Routes } from 'react-router-dom';
import {BrowserRouter as Router} from 'react-router-dom'

import './App.css';
import Header from "./components/header";

import Footer from "./components/footer";


import Cabinets from "./pages/cabinets"
import Commercial from "./pages/commercial"
import Residential from "./pages/residential"
import Home from "./pages/home"




class App extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      
    }
  }
  componentDidMount(){
    console.log("/App")
  }


  render(){
    return (

     
            <div>
            
              

              <Router>
                <Header></Header>
                <Routes>
                  <Route path="/Cabinets" element={<Cabinets/>}/>
                  <Route path="/Commercial"  element={<Commercial/>}/>
                  <Route path="/residential"  element={<Residential/>}/>
                  <Route path="/"  element={<Home/>}/>
                </Routes>
                <Footer></Footer>
              </Router>

              
            </div>
    

    );
  }
}

export default App;
