import React from 'react';

const Footer = () => {
  return (
    <footer >
      <p style={{ padding: '1rem 0' }}>
        &copy; Paintopia LLC
      </p>
    </footer>
  );
};

export default Footer;