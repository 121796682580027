import React from 'react';
import ContactButton from './contact';
import ScrollButton from './scrollButton';
import logo from "../images/logo.png"
import PhoneNumberButton from './phonenumberbutton';

const Header = () => {
  return (
    <header className="header c3">
      <div className="header__left">
   
      <img id="logoImg" src={logo}></img>

        <div className='hidemeuntilmobile '>
          <PhoneNumberButton phoneNumber={2107891121}></PhoneNumberButton>

        </div>
      </div>
      <div className='header__middle  '>
      <PhoneNumberButton phoneNumber={2107891121}></PhoneNumberButton>
      
            <h2 className="headerButton  borderLeft">Commercial</h2>
      
          
            
            <h2 className="headerButton  borderRight">Residential</h2>
      
          
       
      </div>
      <div className="header__right">
     <ScrollButton targetId={"aboutSection"} label="About" offset={100}>

     </ScrollButton>
        
        <ContactButton label="Contact"></ContactButton>
      </div>

    </header>
  );
};

export default Header;
