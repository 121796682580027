
import React from 'react';



const Residential = () => {
    console.log("Home")
  return (
    <div className='subpage'>
    <h3 className='subpage-header'>Residential Painting Services</h3>
    <p>

    </p>
    <div className="subpage-section">
      <h3>Interior</h3>
      <div className="subpage-content flex">
        <div className="subpage-text halfed">
          <p>When you're ready to transform your home's interior with a fresh coat of paint, 
turn to the experts at Paintopia. Our team of professional 
painters is experienced in working with homeowners to ensure that we meet your specific needs 
and expectations. Our team will use high-quality paints and materials 
to ensure that your new paint job is both beautiful and long-lasting. We'll even work around your
 schedule to minimize disruption to your daily routine. </p>
          <p>At Paintopia, we know that your home is your sanctuary, and we take great care to respect
 that during the project.  We understand that moving furniture and shielding valuables can be a hassle, 
so we'll take care of it for you. Our painters are comfortable around pets and 
will take care to not disturb them.</p>
<p>
Trust PaintopiaTX to provide a
 hassle-free interior residential painting experience that will transform your home into the
 beautiful, comfortable space you deserve.
</p>
        </div>
        <div className="subpage-images gridboi" >
          <img id="gridboi1" src=""/>
          <img id="gridboi2" src=""/>
          <img id="gridboi3" src=""/>
        </div>
      </div>
    </div>






    <div className="subpage-section">
      <h3>Cabinets</h3>
      <div className="subpage-content flex flip">
        <div className="subpage-text">
          <p>If you're looking to give your kitchen or bathroom a new look without the expense of a 
            full remodel, consider PaintopiaTX's cabinet painting services. Our experienced team will
             work with you to choose the perfect color and finish for your cabinets. 
        
             We start by cleaning your cabinets
               thoroughly to remove any dirt, grime, or grease. Then, we sand and prep the 
               cabinets to create a smooth surface for painting. We typically take the cabinet 
               doors away to our shop, where we can spray them for a flawless finish. For the 
               boxes, we create an airtight seal in the spray area to keep the smell and dust 
               contained. Our team uses high-quality paints and materials to ensure a 
               long-lasting, durable finish. While we work on your cabinets, we understand that 
               access to your kitchen or bathroom may be limited.

          </p>
         
        </div>
        <div className="subpage-images flex aligncenter">
          <img src=""/>
          <img src=""/>
        </div>
      </div>
    </div>

    <div className="subpage-section">
      <h3>Exterior</h3>
      <div className="subpage-content flex flipvert">
        <div className="subpage-text">
          <p>  Our team of experienced painters is equipped to handle any sized house, from small bungalows to large estates.
 Your home's exterior is the first thing visitors and passersby see, which is why we take great care to ensure
that it looks its best. Our team will replace any damaged boards or siding before we begin painting. We have tall ladders and if necessary, 
we'll build scaffolding to reach hard-to-access areas safely and efficiently. At PaintopiaTX, we're committed to delivering 
high-quality, professional painting services that will make your home look its best.</p>

          <p>At Paintopia, we know that your home's exterior is one of its most important features, which is why we 
take great care to ensure that it looks it's best.
 We'll work with you to choose the right colors and finishes that will make your home stand out from the rest.
 We take great care to protect your landscaping during the painting process, and we'll move any outdoor 
furniture or items out of the way to ensure that they're not damaged. Paintopia is committed to providing professional, high-quality painting 
services that will transform your home's exterior into the beautiful, eye-catching space it deserves to be.
</p>
        </div>
        <div className="subpage-images flex aligncenter">
          <img src=""/>
          <img src=""/>
        </div>
      </div>
    </div>


    <div className="subpage-section">
      <h3>Fences and Outdoor Furniture</h3>
      <div className="subpage-content flex ">
        <div className="subpage-text">
          <p>At PaintopiaTX, we know that your deck, fence, and outdoor furniture are important features of your outdoor living space, 
which is why we offer professional painting and staining services. Whether you're looking to breathe new life into your 
outdoor space or you're in need of repairs, our team of experienced painters is here to help. We'll start by pressure
 washing your deck, fence, or outdoor furniture to remove any dirt, grime, or old paint or stain. If your deck or fence
 has any broken or rotten boards, we'll replace them to ensure a sturdy, safe structure. We take great care to protect your 
landscaping during the painting process, and we'll move any outdoor furniture or items out of 
the way to ensure that they're not damaged.  When it comes to staining, we
 use high-quality products that will protect your outdoor wood surfaces from the elements. Keep in mind that staining will 
need to be reapplied every summer to maintain the color and protection. At PaintopiaTX, we're committed to providing professional, 
high-quality painting and staining services that will make your outdoor living space a beautiful and functional oasis.</p>
         
        </div>
        <div className="subpage-images flex aligncenter">
          <img src=""/>
          <img src=""/>
        </div>
      </div>
    </div>







    


  </div>
  );
};

export default Residential;
