
import React from 'react';
import ImageSlider from "../components/imageSlider";

import AboutSection from "../components/aboutSection";
import Services from "../components/services";
import Section1 from "../components/section1"


const Home = () => {
    console.log("Home")
  return (
    <div id="section80kdot">
        <ImageSlider images={['http://edc.h-cdn.co/assets/16/17/1461599738-cabinets-after2.jpg', 'http://www.thewowstyle.com/wp-content/uploads/2015/01/green-paint-color-modern-living-room.jpg', 'https://images.sherwin-williams.com/content_images/sw-img-living-oranges-007-hdr.jpg']} />
        <div className="wrapper">
        
          <Section1></Section1>
          <Services></Services>
          
          <AboutSection image={""} text={[
            "Paintopia is a family-owned painting company based in San Antonio, Texas. With over 20 years of experience in the industry, Paintopia has established a reputation for delivering high-quality painting services to residential and commercial clients. Our team of professional painters are committed to providing the best customer service, paying close attention to detail and ensuring that each job is completed to the client's satisfaction.",
            "We understand that a successful painting project requires more than just a fresh coat of paint. Our team takes the time to properly prep each surface, patch up any imperfections, and clean up after each day's work. We also take care to protect the client's furniture, floors, and other belongings during the painting process. With a focus on quality and detail, we at Paintopia ensure that each project is completed to the highest standard and will look beautiful for years to come.",
            " Whether you're looking to refresh a single room or paint your entire home or business, Paintopia is the company you can trust to get the job done right."      

          ]}
          ></AboutSection>
          
       
        </div>

    </div>
  );
};

export default Home;
