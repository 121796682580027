
import React from 'react';



const Cabinets = () => {
    console.log("Home")
  return (
    <div className='subpage'>
    <h3 className='subpage-header'>Cabinet Painting Services</h3>
    <p>

    </p>

    <div className="subpage-section">
      <h3>Down-town San Antonio</h3>
      <div className="subpage-content flex">
        <div className="subpage-text halfed">
          <p>At PaintopiaTX, we understand that time is money, and we know that business owners in downtown San Antonio
 can't afford to waste either. That's why we pride ourselves on our punctuality and efficient painting 
services. Our team will arrive on time, ready to work, and complete your project 
as quickly as possible without sacrificing quality. We can work with you to find a time that is most 
convenient for you and your business, so you can continue to focus on what you do
best, while we handle the painting. </p>
          <p>As a business owner in downtown San Antonio, you know how important it is to maintain a professional 
and inviting appearance for your storefront or office space. That's where Paintopia comes in. With 
our years of experience and dedication to customer satisfaction, we can provide you with top-notch 
painting services that will help your business stand out from the rest. Whether you're looking to refresh
 your current paint job, update your color scheme, or make more significant changes, Paintopia has the 
expertise and tools to get the job done efficiently and effectively. </p>
        </div>
        <div className="subpage-images halfed flex">
          <img src=""/>
          <img src=""/>
        </div>
      </div>
    </div>





    <div className="subpage-section">
      <h3>Office Spaces</h3>
      <div className="subpage-content flex flip">
        <div className="subpage-text halfed">
          <p>When it comes to painting your commercial office space in San Antonio, Paintopia has you covered.
 Our team understands the unique needs of office spaces, and we know that you 
can't afford to disrupt your day-to-day operations. That's why we work around your schedule to make sure
 that we get the job done with as little disruption as possible. We take great care to protect your desks,
 equipment, and other sensitive areas during the painting process, and we always leave your space clean
 and tidy when we're finished. Our team has the expertise to recommend the best types of paint and materials 
for your space, taking into account factors like lighting, foot traffic, and overall aesthetic. Trust 
Paintopia to provide high-quality, reliable painting services that will transform your office space into 
the professional, inviting environment you need to succeed.</p>
          <p> At Paintopia, we understand that your office is the face of your business, and we're here to help you make
 it shine. Our team of will work with you to choose the right colors and finishes for your space, 
taking into account your branding, aesthetic, and other important factors. With Paintopia, you can trust 
that your office space will be transformed into the inviting, professional environment that you and your 
team deserve.</p>
        </div>
        <div className="subpage-images gridboi" >
          <img id="gridboi1" src=""/>
          <img id="gridboi2" src=""/>
          <img id="gridboi3" src=""/>
        </div>
      </div>
    </div>


    


  </div>
  );
};

export default Cabinets;
